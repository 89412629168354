import React from "react"
import { Helmet } from "react-helmet"

export default function Services() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Lance – Services"></meta>

        <title>Lance / Services</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="scrollSmooth" />
      </Helmet>

      <>
        Services page
      </>
    </>
  )
}
